$mbsc-ios-accent: #3498B7;




// :root {
//   --event-border-radius: 5px;

//   --color-motionair-blue: #3498B7;
//   --color-text-light: rgb(100, 100, 100);
//   --color-text-dark: rgb(77, 77, 77);
// }

// .mbsc-eventcalendar {
//   height: calc(100vh - 86px);
//   overflow: hidden;
// }


// .event-color-c {
//   display: flex;
//   margin: 16px;
//   align-items: center;
//   cursor: pointer;
// }

// .event-color-label {
//   flex: 1 0 auto;
// }

// .event-color {
//   width: 30px;
//   height: 30px;
//   border-radius: 15px;
//   margin-right: 10px;
//   margin-left: 240px;
// }

// .crud-color-row {
//   display: flex;
//   justify-content: center;
//   margin: 5px;
// }

// .crud-color-c {
//   padding: 3px;
//   margin: 2px;
// }

// .crud-color {
//   position: relative;
//   min-width: 46px;
//   min-height: 46px;
//   margin: 2px;
//   cursor: pointer;
//   border-radius: 23px;
// }

// .crud-color-c.selected,
// .crud-color-c:hover {
//   box-shadow: inset 0 0 0 3px #007bff;
//   border-radius: 48px;
// }

// .crud-color:before {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin-top: -10px;
//   margin-left: -10px;
//   color: #f7f7f7;
//   font-size: 20px;
//   text-shadow: 0 0 3px #000;
//   display: none;
// }

// .crud-color-c.selected .crud-color:before {
//   display: block;
// }




// /* STYLING RED DAYS */
// .red-date-test {
//   background-color: red;
// }




// /* STYLING THE EVENT */

// /* STYLING BUSY EVENT*/
// .md-timeline-template-event.md-timeline-template-event-busy {
//   background: repeating-linear-gradient(45deg,
//       #ff8282a2,
//       #ff8282a2 10px,
//       #e36a6aa2 10px,
//       #e36a6aa2 20px);
// }
// .md-timeline-template-desc-busy {
//   font-size: 0.8rem;
//   margin: 0 10px;
//   color: var(--color-text-dark);
//   font-weight: 400;
// }

// .md-timeline-template-title-busy {
//   font-size: 0.8rem;
//   color: var(--color-text-dark);
//   font-weight: 600;
// }
// .md-timeline-template-time-busy {
//   font-size: 0.7rem;
//   color: var(--color-text-light);
//   font-weight: 400;
// }





// .mbsc-schedule-event-inner {
//   line-height: initial;
// }

// .md-timeline-template .mbsc-schedule-event.mbsc-ltr {
//   height: auto !important;
// }

// .md-timeline-template-event {
//   border: 1px solid transparent;
//   margin: 2px 0;
// }

// .md-timeline-template-event-cont {
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   /* background: rgba(244, 124, 124, 0.8); */
//   font-size: 15px;
//   height: 30px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// }

// .md-timeline-template-event-cont-expand {
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   /* background: rgba(244, 124, 124, 0.8); */
//   font-size: 15px;
//   height: 40px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   padding-top: 5px;
//   padding-bottom: 5px;
// }

// #flex-event {
//   height: 100%;
//   display: flex;
//   align-items: center;
//   overflow: hidden;
// }

// #flex-event-expand {
//   height: 50%;
//   display: flex;
//   align-items: center;
//   overflow: hidden;
// }

// .md-timeline-template-event-cont .mbsc-icon {
//   padding: 5px;
//   box-sizing: content-box;
// }

// .mbsc-timeline-event-start .md-timeline-template-event,
// .mbsc-timeline-event-start .md-timeline-template-event-cont,
// .mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
//   border-top-left-radius: var(--event-border-radius);
//   border-bottom-left-radius: var(--event-border-radius);
//   border-top-right-radius: var(--event-border-radius);
//   border-bottom-right-radius: var(--event-border-radius);
// }



// .md-timeline-template-event-cont .mbsc-icon:before {
//   color: #fff;
//   font-size: 18px;
// }

// .md-timeline-template-desc {
//   font-size: 0.8rem;
//   margin: 0 10px;
//   color: var(--color-text-light);
//   font-weight: 400;
// }

// .md-timeline-template-title {
//   font-size: 0.8rem;
//   color: var(--color-text-dark);
//   font-weight: 600;
// }

// .md-timeline-template-time {
//   font-size: 0.7rem;
//   color: var(--color-text-light);
//   font-weight: 400;
// }

// .md-timeline-template .mbsc-timeline-column,
// .md-timeline-template .mbsc-timeline-header-column {
//   min-width: 100px;
// }

// .md-timeline-template .mbsc-timeline-resource,
// .md-timeline-template .mbsc-timeline-row {
//   min-height: 100px;
// }






// /* STYLING THE RESOURCE */

// div.mbsc-timeline-resources.mbsc-timeline-resource-col.mbsc-flex-col.mbsc-ios.mbsc-ltr>div:nth-child(2) {
//   min-height: 30px;
//   height: 30px;
// }

// .md-timeline-height .mbsc-timeline-resource, .md-timeline-height .mbsc-timeline-row {
//   min-height: 220px;
//   height: 20px;
// }

// .resource-row-container {
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   gap: 1rem;
// }

// .resource-row-1 {
//   flex-grow: 1;
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: column;
// }

// .resource-row-2 {
//   flex-grow: 1;
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: column;
// }

// .last-crew-entry {
//   margin-bottom: 2rem;
// }

// .invoiced {
//   background-color: #FFFFFF;
//   display: flex;
//   justify-content: center;
//   border-bottom: 1px solid #bbbbbb;
//   border-top: 1px solid #bbbbbb;
//   padding-top: 1px;
// }

// /* Remove border below checkbox */
// .invoiced label:after {
//   border-color: white !important;
// }

// .invoiced label:before {
//   border-color: white !important;
// }

// .invoiced.completed * {
//   background-color: rgb(141, 244, 172) !important;
// }

// .invoiced.completed label:after {
//   border-color: rgb(141, 244, 172) !important;
// }

// .invoiced.completed label:before {
//   border-color: rgb(141, 244, 172) !important;
// }

// /* INVOICED EVENT*/
// .mbsc-readonly-event * {
//   background-color: grey !important;
//   color: rgb(255, 255, 255) !important;
//   text-decoration: line-through !important;
//   border-color: black!important;
// }





/* MOBILE MENU */

.motion-air-calendar .mbsc-timeline-resource-empty.mobile-menu-open,
.motion-air-calendar .mbsc-timeline-resource-col.mobile-menu-open,
.motion-air-calendar .mbsc-timeline-resource.mobile-menu-open,
.mbsc-timeline-resource.mbsc-ios.mbsc-ltr.mobile-menu-open-padding {
  width: 0;
  padding-left: 0 !important;
}

.motion-air-calendar.mbsc-ltr .mobile-toggle .mbsc-schedule-event-inner {
  left: 0 !important;
} 

/* 
.motion-air-calendar .mbsc-timeline-resource-empty,
.motion-air-calendar .mbsc-timeline-resource-col,
.motion-air-calendar .mbsc-timeline-resource {
  width: 0;
  padding-left: 0 !important;
}

.motion-air-calendar.mbsc-ltr .mbsc-schedule-event .mbsc-schedule-event-inner {
  left: 0;
} */


@media only screen and (max-width: 700px) {
  /* .mbsc-timeline-resources.mbsc-timeline-resource-col.mbsc-flex-col.mbsc-ios.mbsc-ltr {
    display: none;
  }

  
  
  .mbsc-timeline-resource-empty.mbsc-timeline-resource-col.mbsc-timeline-header-placeholder.mbsc-timeline-header-row-3.mbsc-ios.mbsc-ltr {
    display: none;
  } */


  .reiel {
    display: inherit !important;
  }

}


@media only screen and (min-width: 700px) {
  /* .mbsc-timeline-resources.mbsc-timeline-resource-col.mbsc-flex-col.mbsc-ios.mbsc-ltr {
    display: inherit;
  }
  
  .mbsc-timeline-resource-empty.mbsc-timeline-resource-col.mbsc-timeline-header-placeholder.mbsc-timeline-header-row-3.mbsc-ios.mbsc-ltr {
    display: inherit;
  } */

  #btnHideResource {
    display: none;
  }

  .nav-ul a { 
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
  }


}

/* .mbsc-timeline-resource, .mbsc-timeline-row {
  height: unset!important;
  position: relative;
  flex: 1 0 auto;
  box-sizing: border-box;
} */

/* FIXING RESOURCES WITH CHILDREN HEIGHT */
// .mbsc-flex.mbsc-timeline-row.mbsc-ios.mbsc-timeline-parent,
// .mbsc-timeline-resource.mbsc-ios.mbsc-ltr.mbsc-timeline-parent.mbsc-flex {
//   min-height: 30px !important;
//   height: 30px !important;
//   background: rgba(203, 203, 203, 0.25);
// }

/* 
.resource-header-content {
  display: flex;
}

.resource-header-name {
  height: 20px;
  margin: 0;
}

.resource-header-description {
  height: 20px;

} */


.today-hightlight {
  border: 1px solid red;
  background-color: red !important;
}



.md-tooltip-content-container {
  padding: 5px 5px 5px 20px;
}

.md-tooltip-container h1 {
  font-size: 1.2rem;
}

.md-tooltip-container h2 {
  font-size: 1rem;
}

.md-tooltip-container p {
  font-size: 0.8rem;
}

.md-tooltip-header {
  padding: 5px 5px 5px 20px;
}
.md-tooltip-content {
  padding-bottom: 1rem;
}








@import "~@mobiscroll/react/dist/css/mobiscroll.react.scss"
