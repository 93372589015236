/* a {
  all: unset;
} */


ul {
  display: block;
  list-style-type: disc;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.nav-ul {
  /* margin: 1rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  gap: 2rem;
  padding: 1rem 2rem 1rem 0rem;
  background-color: #2e2e2e;
}

.logo-image {
  padding-left: 30px;
  height: 50px;
}

a {
  padding: 0.3rem 1rem 0.3rem 1rem;
  list-style: none;
  text-decoration: none;
  color: rgb(212, 212, 212);
}

a:hover {
  /* padding: 0.3rem 1rem 0.3rem 1rem; */
  cursor: pointer;
  color: #3598b7;
}

.active {
  color: #3598b7;

}

.regular {
  
}
.regular:hover {
  /* padding: 0.3rem 1rem 0.3rem 1rem; */
  cursor: pointer;
  color: #3598b7;
} 

.log-out-container {
  padding: 2px 5px;
  background-color: #2E2E2E;
  color: #3498B7;
  margin-left: auto;
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
}

.log-out-container:hover * {
  padding: 2px 5px;
  background-color: #2E2E2E;
  color: #3498B7;
  border-radius: 3px;
  margin-left: auto;
  cursor: pointer;
}
.log-out-btn {
  padding: 2px 5px;
  background-color: #2E2E2E;
  color: rgb(212, 212, 212);
  border: none;
  margin-left: auto;
  font-size: 1rem;
}
.log-out-btn:hover {

}


@media only screen and (max-width: 768px) {


  .logo-image {
    display: none;
  }

  .nav-ul {
    display: flex;
    gap: 0;
  }

  .nav-ul *{
    padding: 0.1rem 0.3rem 0.1rem 0.3rem!important;
  }

  .css-mnf8oi-MuiDrawer-docked, .css-mnf8oi-MuiDrawer-docked .MuiDrawer-paper {
    width: 180px!important;
  }

  .mobile-menu {
    /* margin: 1rem; */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    padding: 0.5rem 0rem 0.5rem 0rem;
    background-color: #2e2e2e;
    transition: all 2s;
    height: auto;
  }
  /* .nav-ul {
    gap: 4vw;
    padding: 0.5rem;
  }
  .log-out-btn {
    border: 1px solid rgb(148, 148, 148);
    padding: 5px 0 5px 0;

  }

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
} */




/* .nav-ul {
  display: none;
}
.mobile-menu {
  display: block;

} */
}