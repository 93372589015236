
.login-container {
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 150%;
	padding: 80px 40px;
	background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  backdrop-filter: blur(2px);
	z-index: 999;
}

.login-form {
  padding: 2rem 2rem 2rem 2rem;
	background: rgba(0, 0, 0, 0.65);
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  height: 200px;
  width: 300px;
  border-radius: 0.5rem;
}
.avatar {
	position: absolute;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
	top: calc(-80px/2);
	left: 190px;
}
.login-form h2 {
	font-size: 1.5rem;
	margin: 0;
	padding: 0 0 20px;
	color: #fff;
	text-align: center;
}
.login-form p {
	margin: 0;
	padding: 0;
	font-weight: bold;
	color: #fff;
}
.login-form input {
	width: 100%;
	margin-bottom: 20px;
}
.login-form input[type=email], .login-form input[type=password] {
	border: none;
	border-bottom: 1px solid #fff;
	background: transparent;
	outline: none;
	height: 40px;
	color: #fff;
	font-size: 16px;
}
.login-form input[type=submit] {
	height: 30px;
	color: #fff;
	font-size: 15px;
	background: red;
	cursor: pointer;
	border-radius: 25px;
	border: none;
	outline: none;
	margin-top: 15%;
}
.login-form a {
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	text-decoration: none;
}
.login-form button {
		background-color: rgba(192, 192, 192, 0.05);
		border-radius: 8px;
		border-width: 0;
		color: #e5e5e5;
		cursor: pointer;
		display: inline-block;
		font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		list-style: none;
		margin: 0;
		padding: 10px 12px;
		text-align: center;
		transition: all 200ms;
		vertical-align: baseline;
		white-space: nowrap;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		width: 100%;
}
input[type=checkbox] {
	width: 20%;
}
