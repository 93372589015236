/* Style the container */
.date-picker {
  margin-top: 20px;
  width: 100%;
}

/* Style the input field */
.date-picker :global(.mbsc-textfield) {
  width: 100%;
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  background-color: #fff;
}

/* Optional: Style the input on hover */
.date-picker :global(.mbsc-label)::before,
.date-picker :global(.mbsc-label)::after {
  border-color: #ffffff00;
}

/* Optional: Style the input when focused */
.date-picker :global(.mbsc-textfield:not(:disabled):hover) {
  border-color: #1976d2;
  border-width: 2px;
  outline: none;
}
.date-picker :global(.mbsc-textfield:focus) {
  border-color: #1976d2;
  border-width: 2px;
  outline: none;
}

/* Optional: Style the input when disabled */
.date-picker :global(.mbsc-textfield:disabled) {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
  border-color: rgba(0, 0, 0, 0.23);
}

.spinner-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}