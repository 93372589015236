:root {
  --event-border-radius: 5px;

  --color-motionair-blue: #3498B7;
  --color-text-light: rgb(100, 100, 100);
  --color-text-dark: rgb(77, 77, 77);
}

.mbsc-eventcalendar {
  height: calc(100vh - 86px);
  overflow: hidden;
}


.event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}

.event-color-label {
  flex: 1 0 auto;
}

.event-color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 240px;
}

.crud-color-row {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.crud-color-c {
  padding: 3px;
  margin: 2px;
}

.crud-color {
  position: relative;
  min-width: 46px;
  min-height: 46px;
  margin: 2px;
  cursor: pointer;
  border-radius: 23px;
}

.crud-color-c.selected,
.crud-color-c:hover {
  box-shadow: inset 0 0 0 3px #007bff;
  border-radius: 48px;
}

.crud-color:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  color: #f7f7f7;
  font-size: 20px;
  text-shadow: 0 0 3px #000;
  display: none;
}

.crud-color-c.selected .crud-color:before {
  display: block;
}




/* STYLING RED DAYS */
.red-date-test {
  background-color: red;
}




/* STYLING THE EVENT */

/* STYLING BUSY EVENT*/
.md-timeline-template-event.md-timeline-template-event-busy {
  background: repeating-linear-gradient(45deg,
      #ff8282a2,
      #ff8282a2 10px,
      #e36a6aa2 10px,
      #e36a6aa2 20px);
}

.md-timeline-template-desc-busy {
  font-size: 0.8rem;
  margin: 0 10px;
  color: var(--color-text-dark);
  font-weight: 400;
}

.md-timeline-template-title-busy {
  font-size: 0.8rem;
  color: var(--color-text-dark);
  font-weight: 600;
}

.md-timeline-template-time-busy {
  font-size: 0.7rem;
  color: var(--color-text-light);
  font-weight: 400;
}





.mbsc-schedule-event-inner {
  line-height: initial;
}

.md-timeline-template .mbsc-schedule-event.mbsc-ltr {
  height: auto !important;
}

.md-timeline-template-event {
  border: 1px solid transparent;
  margin: 2px 0;
}

.md-timeline-template-event-cont {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* background: rgba(244, 124, 124, 0.8); */
  font-size: 15px;
  height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.md-timeline-template-event-cont-expand {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* background: rgba(244, 124, 124, 0.8); */
  font-size: 15px;
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
}

#flex-event {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#flex-event-expand {
  height: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.md-timeline-template-event-cont .mbsc-icon {
  padding: 5px;
  box-sizing: content-box;
}

.mbsc-timeline-event-start .md-timeline-template-event,
.mbsc-timeline-event-start .md-timeline-template-event-cont,
.mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
  border-top-left-radius: var(--event-border-radius);
  border-bottom-left-radius: var(--event-border-radius);
  border-top-right-radius: var(--event-border-radius);
  border-bottom-right-radius: var(--event-border-radius);
}



.md-timeline-template-event-cont .mbsc-icon:before {
  color: #fff;
  font-size: 18px;
}

.md-timeline-template-desc {
  font-size: 0.8rem;
  margin: 0 10px;
  color: var(--color-text-light);
  font-weight: 400;
}

.md-timeline-template-title {
  font-size: 0.8rem;
  color: var(--color-text-dark);
  font-weight: 600;
}

.md-timeline-template-time {
  font-size: 0.7rem;
  color: var(--color-text-light);
  font-weight: 400;
}

.md-timeline-template .mbsc-timeline-column,
.md-timeline-template .mbsc-timeline-header-column {
  min-width: 100px;
}

.md-timeline-template .mbsc-timeline-resource,
.md-timeline-template .mbsc-timeline-row {
  min-height: 100px;
}






/* STYLING THE RESOURCE */

div.mbsc-timeline-resources.mbsc-timeline-resource-col.mbsc-flex-col.mbsc-ios.mbsc-ltr>div:nth-child(2) {
  min-height: 30px;
  height: 30px;
}

.md-timeline-height .mbsc-timeline-resource, .md-timeline-height .mbsc-timeline-row {
  min-height: 220px;
  height: 20px;
}

.resource-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.resource-row-1 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.resource-row-2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.last-crew-entry {
  margin-bottom: 2rem;
}

.invoiced {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #bbbbbb;
  border-top: 1px solid #bbbbbb;
  padding-top: 1px;
}

/* Remove border below checkbox */
.invoiced label:after {
  border-color: white !important;
}

.invoiced label:before {
  border-color: white !important;
}

.invoiced.completed * {
  background-color: rgb(141, 244, 172);
}

.invoiced.completed label:after {
  border-color: rgb(141, 244, 172) !important;
}

.invoiced.completed label:before {
  border-color: rgb(141, 244, 172) !important;
}




.inner-wrapper {
  width: 40000px;
}

.motion-air-calendar-wrapper {
  position: relative;
  width: auto;
  overflow: scroll;
}



/* INVOICED EVENT*/
.mbsc-readonly-event * {
  background-color: grey !important;
  color: rgb(255, 255, 255) !important;
  text-decoration: line-through !important;
  border-color: black !important;
}

/* RENTMAN STYLING */
.mbsc-timeline-row-gutter { height: 6px !important; }
/* .mbsc-timeline-row { height: 1px !important; } */

.mbsc-readonly-event .rentman-event {
  color: rgb(255, 255, 255) !important;
  text-decoration: none !important;
  border-color: rgb(0 0 0) !important;
  background: rgb(148 148 148 / 40%) !important;
} 
.mbsc-readonly-event .rentman-event * {
  color: rgb(116, 116, 116) !important;
  text-decoration: none !important;
  border-color: rgb(0 0 0) !important;
  background: rgb(148 148 148 / 0%) !important;
} 
.rentman-event {
  border-color: rgb(0 0 0) !important;
  background: rgb(148 148 148 / 40%) !important;
}
/* .rentman-event-inner {
  height: 20px;
  display: inline-flex !important;
  padding-top: 0;
  padding-bottom: 0;
} */


/* MOBILE MENU */

.mobile-menu-open {
  display: none;
  /* position: absolute;
  width: 0;
  padding-left: 0 !important;
  z-index: 9999; */
}

.mbsc-timeline-resource-col, .mbsc-timeline-sidebar-col {
  width: 170px;
}

.mbsc-timeline-resources, .mbsc-timeline-sidebar {
  width: 170px;
}

.mobile-menu-update-open {
  position: absolute;
  left: 0 !important;
  z-index: 9999;

}


@media only screen and (max-width: 700px) {
  .nav-ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    padding: 1rem 2rem 1rem 0rem;
    background-color: #2e2e2e;
    gap: 0;
  }

  .reiel {
    display: inherit !important;
  }

  .cursor-users-online {
    display: none !important;
    margin-right: 8rem;
  }

}


@media only screen and (min-width: 700px) {
  #btnHideResource {
    display: none;
  }


}



.mbsc-timeline-parent {
  min-height: 30px !important;
  height: 30px !important;
  background: rgba(203, 203, 203, 0.25);
}

/* 
.mbsc-flex.mbsc-timeline-row.mbsc-ios.mbsc-timeline-parent,
.mbsc-timeline-resource.mbsc-ios.mbsc-ltr.mbsc-timeline-parent.mbsc-flex {
  min-height: 30px !important;
  height: 30px !important;
  background: rgba(203, 203, 203, 0.25);
} */


.today-hightlight {
  border: 1px solid red;
  background-color: red !important;
}

.cursor-image {
  position: absolute;
  z-index: 3;
}

.mbsc-calendar-wrapper.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios.mbsc-calendar-wrapper-fixed {
  z-index: 7;
}

.mbsc-timeline-grid.mbsc-flex-1-1 {
  position: relative !important;
}

.flex.pl-3 {
  display: flex !important;
  margin-left: auto;
  margin-right: 1rem;
}

.relative.ml-8 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: -0.8rem;
  width: 1rem;
  height: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  position: 'absolute';
  font-size: 28;
  /* background: grey; */
  box-shadow: inset 0px 0px 0px 2px black, 0px 8px 16px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

.mbsc-ios.mbsc-button-standard {
  background: none !important;
}

.popup-desc {
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}

.popup-time {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.header-date-container {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;

}

.header-date-day {
  font-size: 0.8rem;
  font-weight: 400;
}

.header-date-date {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
}

.calendar-holiday {
  background-color: #fbc7c7 !important;
}

.calendar-saturday {
  background-color: #f7f2f2;

}

.calendar-sunday {
  background-color: #fff4f4;

}

.calendar-today {
  background-color: #e2ffe3;

}


/* .mbsc-flex.mbsc-flex-1-1 {
  color: red!important;
  opacity: 0.5 !important;
} */
.mbsc-timeline-grid.mbsc-flex-1-0::-webkit-scrollbar {
  width: 52px;
  /* width of the entire scrollbar */
}

.mbsc-timeline-grid.mbsc-flex-1-0::-webkit-scrollbar-thumb {
  background-color: blue;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid orange;
  /* creates padding around scroll thumb */
}

.mbsc-timeline-grid.mbsc-flex-1-0::-webkit-scrollbar-track {
  background-color: #fca5a5;
}




/*
Jueferie styles
*/
.md-timeline-template-desc-christemas {
  font-size: 0.8rem;
  margin: 0 10px;
  color: var(--color-text-dark);
  font-weight: 400;
}

.md-timeline-template-title-christemas {
  font-size: 0.8rem;
  color: var(--color-text-dark);
  font-weight: 600;
  background-color: white;
}

.md-timeline-template-time-christemas {
  font-size: 0.7rem;
  color: var(--color-text-light);
  font-weight: 400;
}

.strand {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.strand li {
  position: relative;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-interation-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #f02241;
  box-shadow: 0px 4.66667px 24px 3px #f02241;
  -webkit-animation-name: flash-1;
  animation-name: flash-1;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.strand li:nth-child(2n+1) {
  background: #42b261;
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
  -webkit-animation-name: flash-2;
  animation-name: flash-2;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.strand li:nth-child(4n+2) {
  background: #6a27fb;
  box-shadow: 0px 4.66667px 24px 3px #6a27fb;
  -webkit-animation-name: flash-3;
  animation-name: flash-3;
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
}

.strand li:nth-child(odd) {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.strand li:nth-child(3n+1) {
  -webkit-animation: 1.4s;
  animation-duration: 1.4s;
}

.strand li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.33333px;
  border-radius: 3px;
  top: -4.66667px;
  left: 1px;
}

.strand li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.66667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.strand li:last-child:after {
  content: none;
}

.strand li:first-child {
  margin-left: 40px;
}

@-webkit-keyframes flash-1 {
  0%, 100% {
    background: #f02241;
    box-shadow: 0px 4.66667px 24px 3px #f02241;
  }

  50% {
    background: rgba(240, 34, 65, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(240, 35, 65, .02);
  }
}

@keyframes flash-1 {

  0%,
  100% {
    background: #f02241;
    box-shadow: 0px 4.66667px 24px 3px #f02241;
  }

  50% {
    background: rgba(240, 34, 65, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(240, 35, 65, 0.2);
  }
}

@-webkit-keyframes flash-2 {

  0%,
  100% {
    background: #42b261;
    box-shadow: 0px 4.66667px 24px 3px #42b261;
  }

  50% {
    background: rgba(66, 178, 97, 0.4);
    box-shadow: 0px 4.6667px 24px 3px rgba(66, 178, 97, 0.2);
  }
}

@keyframes flash-2 {

  0%,
  100% {
    background: #42b261;
    box-shadow: 0px 4.66667px 24px 3px #42b261;
  }

  50% {
    background: rgba(66, 178, 97, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(66, 178, 97, 0.2);
  }
}

@-webkit-keyframes flash-3 {

  0%,
  100% {
    background: #6a27fb;
    box-shadow: 0px 4.6667px 24px 3px #6a27fb;
  }

  50% {
    background: #6a27fb21;
    box-shadow: 0px 4.66667px 24px 3px #6a27fb21;
  }
}

@keyframes flash-3 {

  0%,
  100% {
    background: #6a27fb;
    box-shadow: 0px 4.66667px 24px 3px #6a27fb;
  }

  50% {
    background: #6a27fb21;
    box-shadow: 0px 4.66667px 24px 3px #6a27fb21;
  }
}



.christmas {
  background:
    linear-gradient(#0004,
      #0000 0.25em 1em,
      #0005 1.5em),
    repeating-linear-gradient(135deg,
      #c30031 0 0.98em,
      #fff 1em 1.78em,
      #c30031 1.8em 1.88em,
      #fff 1.9em 1.98em,
      #c30031 2em 2.08em,
      #fff 2.1em 2.18em,
      #c30031 2.2em 2.28em,
      #fff 2.3em 3.08em,
      #c30031 3.1em) 0 0 / 4.375em 100%;
  border: none !important;

}





.firebase-emulator-warning {
  display: none !important;
}