.settings-content {
  display: flex;
}

.avatar-settings-container {
  display: flex;
  place-content: center;
  position: relative;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  background-color: white;
  margin-left: -0.8rem;
  align-items: center;
  align-self: center;
}

.avatar-settings-container p {
  font-weight: 900;
}

.avatar-settings-background {
  display: flex;
  place-content: center;
  position: relative;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.avatar-settings-background .avatar-text {
  visibility: hidden;
  width: max-content;
  background-color: rgb(168, 168, 168);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  top: 50px;
  z-index: 9999 !important;
}

.avatar-settings-background:hover .avatar-text {
  visibility: visible;
}

.avatar:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  opacity: 0;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: white;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-bottom: 10px;
  z-index: 9999 !important;
  background: black;
  white-space: nowrap;
}

.avatar:hover:before {
  opacity: 1;
  z-index: 9999 !important;

}












/* SETTINGS CSS */

.color-selector {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  flex-direction: row;
  position: relative;
}

.w-color-colorful {
  width: 120px !important;
}

.w-color-interactive.w-color-saturation.w-color-colorful {
  height: 80px !important;
}

.color-selector>div:first-child::before {
  content: "BACKGROUND";
  position: absolute;
  top: -22px;
  left: 2px;
  opacity: 1;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: black;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-bottom: 10px;
  z-index: 9999 !important;
  /* background: black; */
  white-space: nowrap;
}

.color-selector>div:last-child::after {
  content: "BORDER";
  position: absolute;
  top: -22px;
  left: 16px;
  opacity: 1;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: black;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-bottom: 10px;
  z-index: 9999 !important;
  /* background: black; */
  white-space: nowrap;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  top: initial;
}


.user-header {
  padding-top: 1rem;
  display: flex;
  gap: 10rem;
}

.edit-image-hover {
  background-color: salmon;
  color: white;
}

.settings-user-image-container {
  position: relative;
  display: flex;
  width: 30%;
}
.settings-user-image-container img {
  width: 100%;
}

.settings-user-image-container:hover .settings-user-image-btn-edit {
  /* display: block; */
}

.settings-user-image-btn-edit {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  background-color: #ffffffbf !important;
  border-radius: 0 !important;
}

.image-cropper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}