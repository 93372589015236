.avatar-container {
  display: flex;
  place-content: center;
  position: relative;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  background-color: white;
  margin-left: -0.8rem;
  align-items: center;
}

.avatar-container p {
  font-weight: 900;
}

.avatar-background {
  display: flex;
  place-content: center;
  position: relative;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.avatar-background .avatar-text {
  visibility: hidden;
  width: max-content;
  background-color: rgb(168, 168, 168);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  top: 50px;
  z-index: 9999 !important;
}

.avatar-background:hover .avatar-text {
  visibility: visible;
}

.avatar:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  opacity: 0;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: white;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-bottom: 10px;
  z-index: 9999 !important;
  background: black;
  white-space: nowrap;
}

.avatar:hover:before {
  opacity: 1;
  z-index: 9999 !important;

}
