body {
  background-color: #ffffff;
}

.main-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  max-width: 1200px;
}

.todo-list-container {
  padding: 0.2rem 1rem 0.2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  border-left: 1px solid var(--color-motionair-blue);
  margin-left: auto;
}

.todo-form-header {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

}

.todo-list-header {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;
}

.todo-form-header h2 {
  margin: 0;
  margin-right: 1rem;
}

.todo-list-header h2 {
  margin: 0;
  margin-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* .todo-form-header h2::after {
  display: inline-block;
  content: ' ';
  background-image: url('../img/edit-icon.svg');
  background-size: 47px 47px;
  height: 47px;
  width: 47px;
  background-position: 7px 13px;
} */

.todo-item input {
  /* outline: 1px solid black; */
  border-radius: 6px;
  font-size: 1rem;
  font-family: inherit;
  margin-right: 0.5rem;
}

.todo-item input:hover {
  cursor: pointer;
}


.list-form {
  display: flex;
}

.add-list {
  display: flex;
  margin-bottom: 1rem;
}

.add-list .todo-list-input {
  padding-right: 1rem;
}
/* .add-list * {
  justify-content: center;
} */

/* .add-list input {
  padding: 0.5rem 1rem 0.5rem 1rem;
} */

.list-item-container, .todo-item-container {
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: .25rem .25rem .25rem .25rem;
}

.list-header-main, .todo-header-main {
  height: 12rem;
}

.todo-item-container {
  justify-content: space-around;
}

.list-item {
  width: 100%;
  font-size: 0.9rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: .25rem 0rem 0rem .25rem;
}

.list-item:hover {
  background-color: var(--color-motionair-blue);
}

.list-item-container button, .todo-item-container button, .add-list button {

  align-items: center;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0rem .25rem .25rem 0rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.btn-edit-list {
  border-radius: 0px !important;
  border-left: 1px solid lightgray ! important;
  color: black !important;
}

.btn-edit-list:hover {
  color: green !important;
}

.btn-edit {
  margin-left: auto !important;
  max-width: 1rem;
}

.btn-delete {
  max-width: 1rem;
}

.btn-add:hover {
  color: green;

}

.btn-edit:hover {
  color: green;
}

.btn-delete:hover {
  color: red;
}

.btn-checked {
  margin-right: 1rem !important;
  color: var(--color-motionair-blue) !important;
  border-color: var(--color-motionair-blue) !important;
}



.active-list {
  background-color: var(--color-motionair-blue)!important;
}


.current-todo-container {
  padding: 0.2rem 1rem 0.2rem 1rem;

  width: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.current-todo-container .todo-item {
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 6px;
  transition: all .3s ease-out;
}


.todo-content {
  padding: 0.5rem;
  flex-grow: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.todo-content div {
  font-size: 0.9rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.todo-item div {
  width: 100% !important;
}

.todo-content.todo-header {
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  display: block;
  font-size: 1.5em;
  /* margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  font-weight: bold;
}

.todo-done {
  background-color: #aefcae !important;
  text-decoration: line-through !important;
}


.todo-item-drag-icon {
  display: inline-flex;
}












@media only screen and (max-width: 700px) {

  .main-container {
    flex-direction: column-reverse;
    height: initial;
  }

  .todo-list-container {
    margin-left: initial;
    height: auto;
    padding-bottom: 3rem;
    border-bottom: 3px solid var(--color-motionair-blue);
    /* background-color: #eeeeee; */
  }

  .add-list input {
    min-width: 10px !important;
  }

  .list-header-main {
    height: initial;
  }

  .todo-item-drag-icon {
    display: none;
  }


}


@media only screen and (min-width: 700px) {
  .todo-list-container {
    margin-left: auto;
  }
}